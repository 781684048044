import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import YouTube from "../components/youtube";


export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-band-impulse-heft-1-that-image' className="closeX"><span>X</span></Link>
      <h2>Mixing-Projekt zu <i>That Image</i></h2>
    </div>
    <div style={{ 'marginTop': '20px' }}>
      <p>
        Lernen Sie die Stimmen des Songs <i>That Image</i> kennen, indem Sie die Lautstärke an den einzelnen Tracks verändern. Wenn Sie sich Einzelspuren angehört haben und die Tracks nicht mehr
        synchron gespielt werden, verwenden Sie die Schaltflächen 'Reset' und 'Start / Stopp' zum Synchronisieren. Die Lautstärke lässt sich an den Audioplayern für jeden Kanal einzeln regeln
        und mit dem Slider für alle Spuren gleichzeitig. Achten Sie darauf, dass die Sounddateien geladen sind, bevor es losgeht. Viel Spaß beim Mixen meines Bandklassen-Songs <i>That Image</i>...
      </p>
      <div><Link to="/oer-band-impulse-heft-1-that-image"><img src="/data/audio/EliseInBlack/LeadGitarre.mp3" alt="" /></Link></div>
    </div>
    <div style={{ 'marginTop': '40px', 'display': 'flex' }}>
      <button style={{ 'marginRight': '10px' }} id="startStoppButton" onClick={() => onStartStoppClick()}>Start / Stopp</button>       
      <button style={{ 'marginRight': '10px' }} id="resetButton" onClick={() => onResetClick()}>Reset</button>       
      <input style={{ 'marginTop': '4px' }} id="volumeButton" onChange={() => onVolumeChange()} type="range" step="1" />
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/MelodieMozart.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Bildnisarien-Melodie (Mozart)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Melodie2.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Mittelstimme (2. Stimme)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Melodie3.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Unterstimme (3. Stimme)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/LeadGitarre.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Leadgitarre (Terzen zur Melodie Mozarts)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Gitarre.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Akkordgitarre</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Powerchords.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Powerchords</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/E-Bass.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">E-Bass</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/KeyboardMelodie.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard (Melodie)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/KeyboardHarmonie.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard (Harmonie)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Klatschen.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Händeklatschen</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Cajon.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Cajon</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Conga.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Conga</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/ThatImage/Drums.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Drums</p>
      </div>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div>
        <p>Ulrich Kaiser: That Image (Aufführungsvorschlag)</p>
        <audio src="/data/audio/ThatImage/Auffuehrungsvorschlag.mp3" controls id="thatImage">
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
         <p>
          Versuchen Sie, Den Aufführungsvorschlag und <i>All About That Bass</i> von Meghan Trainor und Kevin Kadish zu synchronisieren und hörend zu vergleichen.
          Viel Spaß und ein bisschen Geduld...
        </p>
      </div>
      <div className="ytVideoFrame">
        <YouTube videoSrcURL={'https://www.youtube.com/embed/7PCkvCPvDXk'} videoTitle={'Meghan Trainor - All About That Bass'} />
        <p>Megan Trainer und Kevin Kadish: <i>All About that Bass</i> (Youtube-Video)</p>
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Mixing Projekt</title>
      <meta name="description" content="Hier finden Sie ein Mixing-Projekt zum Studieren des Arrangements sowie der Einzelstimmen zum einem OpenBook (OER) von Ulrich Kaiser für die Bandklasse." />
      <meta name="keywords" content="Projekt, Bandklasse, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

